.number-pagination {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 20px 0px;

    &[data-align='center'] {
        justify-content: center;
    }

    &[data-align='left'] {
        justify-content: flex-start;
    }

    &[data-align='right'] {
        justify-content: flex-end;
    }
}
