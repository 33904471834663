.dashboard-payments {
    .schedule-btn:not(.MuiLoadingButton-loading):not(.Mui-disabled).MuiButton-contained {
        background-color: $green !important;
    }

    .status-chip {
        height: 29px !important;
        min-width: 100px;
        color: #fff;

        &[data-status='DUE PAYMENT'] {
            background-color: $error-1;
        }

        &[data-status='READY'] {
            background-color: $green;
        }

        &[data-status='PROCESSING'] {
            background-color: $yellow;
        }
    }
}

@import './collected';
@import './received';
@import './cleared';
