.order-purchase-modal {
    .title-wrap {
        display: flex;
        flex-direction: column;
        gap: 5px;

        .sub-title {
            color: $grey-1;
        }
    }

    .modal-buttons {
        justify-content: center;

        .button {
            max-width: 335px !important;
        }
    }

    .signature-image {
        border-radius: 7px;
        padding: 10px;
        border: 2px solid rgba(0, 0, 0, 0.2);
    }
}
