.content-dashboard {
    display: flex !important;
    flex-direction: column;
    gap: 30px;

    .top-cards {
        /* display: flex;
        flex-wrap: wrap; */
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(250px, 300px));
        gap: 20px;
        width: 100%;

        .top-card {
            display: flex;
            flex-direction: column;
            border-radius: 10px;
            min-width: 250px;
            // max-width: 370px;
            width: 100%;
            font-size: 0.8rem;

            &.fix-height {
                height: max-content;

                .mid {
                    .divider {
                        min-height: 100px !important;
                    }
                }
            }

            &.purple {
                background-color: rgba(255, 207, 255, 0.4);
                border: 1px solid rgba(225, 174, 225, 0.2);

                .top {
                    border-bottom: 1px solid rgba(225, 174, 225, 0.2);
                }

                .bottom {
                    border-top: 1px solid rgba(225, 174, 225, 0.2);
                }

                .mid {
                    .divider {
                        width: 1px;
                        height: 100%;
                        background-color: rgba(225, 174, 225, 0.2);
                    }
                }
            }

            &.blue {
                background-color: rgba(226, 218, 255, 0.4);
                border: 1px solid rgba(198, 189, 227, 0.2);
                .top {
                    border-bottom: 1px solid rgba(198, 189, 227, 0.2);
                }

                .bottom {
                    border-top: 1px solid rgba(198, 189, 227, 0.2);
                }

                .mid {
                    .divider {
                        width: 1px;
                        height: 100%;
                        background-color: rgba(198, 189, 227, 0.2);
                    }
                }
            }

            &.yellow {
                background-color: rgba(254, 228, 68, 0.4);
                border: 1px solid rgba(221, 199, 63, 0.2);

                .top {
                    border-bottom: 1px solid rgba(221, 199, 63, 0.2);
                }

                .bottom {
                    border-top: 1px solid rgba(221, 199, 63, 0.2);
                }

                .mid {
                    .divider {
                        width: 1px;
                        height: 100%;
                        background-color: rgba(221, 199, 63, 0.2);
                    }
                }
            }

            &.green {
                background-color: rgba(228, 255, 107, 0.4);

                .top {
                    border-bottom: 1px solid rgba(196, 215, 109, 0.2);
                }

                .bottom {
                    border-top: 1px solid rgba(196, 215, 109, 0.2);
                }

                .mid {
                    .divider {
                        width: 1px;
                        height: 100%;
                        background-color: rgba(196, 215, 109, 0.2);
                    }
                }
            }

            .top,
            .mid,
            .bottom {
                padding: 15px 25px;
            }

            .mid {
                padding: 0px 25px;

                .section {
                    padding: 10px 5px;
                }
            }

            .top {
                text-align: center;
            }

            .mid,
            .bottom {
                display: flex;
                gap: 10px;

                .section {
                    display: flex;
                    flex-direction: column;
                    gap: 10px;
                    width: 100%;

                    .value {
                        font-weight: 500;
                        font-size: $px30;
                    }
                }
            }

            .bottom {
                align-items: center;

                .section {
                    text-align: center;
                }
            }
        }
    }

    .chart-section {
        .body {
            padding: 25px !important;
            max-height: 471px;
        }
    }

    .tables {
        display: flex;
        gap: 20px;
        width: 100%;
    }

    .form-section {
        .table {
            width: 100% !important;
            min-width: 50% !important;
        }

        .page-table .pagination {
            min-width: 50% !important;
        }

        .title {
            font-size: $px20;
            font-weight: 500;
            color: $theme;
        }

        .image {
            max-width: 70px;
            border-radius: 5px;
        }
    }
}

@media screen and (max-width: 1250px) {
    .content-dashboard {
        .top-cards {
            grid-template-columns: repeat(3, auto);
        }
    }
}

@media screen and (max-width: 960px) {
    .content-dashboard {
        .top-cards {
            grid-template-columns: repeat(2, auto);
        }
    }
}

@media screen and (max-width: 600px) {
    .content-dashboard {
        .top-cards {
            grid-template-columns: repeat(1, auto);
        }
    }
}

@import './user.scss';
