#about {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 70px;
    padding-top: 80px;
    padding-bottom: 80px;

    .image-wrap {
        .image {
            width: 100%;
            border-radius: 14px;
            max-width: 554px;
        }
    }

    .details {
        display: flex;
        flex-direction: column;
        gap: 40px;

        .section {
            display: flex;
            flex-direction: column;
            gap: 20px;
            width: 100%;
            max-width: 500px;
        }

        .title {
            font-size: $px36;
            font-weight: 500;
        }

        .buttons {
            display: flex;
            flex-direction: column;
            gap: 10px;

            .button {
                width: 100% !important;
                max-width: 346px;
                text-transform: uppercase !important;
                font-weight: 700 !important;

                .text {
                    width: 100%;
                    max-width: 80px;
                }
            }

            .icon {
                font-size: 1.2em !important;
                color: #000 !important;
            }
        }
    }
}

@media screen and (max-width: 1024px) {
    #about {
        flex-direction: column;
    }
}
