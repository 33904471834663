.card {
    display: block;
    width: 100%;
    border-radius: 15px;
    box-shadow: $box-shadow;

    .skeleton {
        width: 100% !important;
    }

    &.mx-width {
        max-width: max-content;
    }

    &:hover {
        .image-wrap {
            .image {
                transform: scale(1.05);
            }
        }
    }

    .content {
        position: relative;
        display: flex;
        width: 100%;
        height: 100%;
        max-height: 335.36px;
        max-width: 318.38px;
        border-radius: 15px;
        overflow: hidden;
    }

    .title {
        position: absolute;
        top: 18px;
        left: 25px;
        font-size: $px20;
        max-width: 75%;
        z-index: 1;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 100%;
        overflow: hidden;
        // max-width: 157.84px;
    }

    .image-wrap {
        width: 100%;
        //height: 335.36px;
        min-height: 100%;
        max-height: 335.36px;
        max-width: 318.38px;
        overflow: hidden;
        border-radius: 10px;

        .image {
            object-fit: cover;
            height: 100%;
            width: 100%;
            transition: all 0.4s ease;
        }
    }
}

@media screen and (max-width: 570px) {
    .card {
        .title {
            font-size: $px14;
        }
    }
}
