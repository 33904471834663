$sidebar-width: 270px;

#dashboard-container {
    position: relative;
    display: flex;
    min-height: 100vh;
    width: 100%;

    .side-bar {
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: $sidebar-width;
        background-color: #fff;
        height: 100%;
        padding: 45px 0;

        .item {
            box-shadow: none;

            &.Mui-expanded {
                margin: 0 !important;
            }

            .item-summary {
                display: flex;
                align-items: center;
                padding: 0 20px !important;
                cursor: pointer;

                .Mui-expanded {
                    margin: 12px 0 !important;
                    min-height: 0 !important;
                }

                .MuiAccordionSummary-content {
                    margin: 0 !important;
                }

                &[data-active='true'] {
                    background-color: $light-theme;
                    color: $theme;
                }
            }

            .item-route {
                padding: 10px;
                &[data-active='true'] {
                    color: $theme;
                }
            }

            .item-title {
                display: flex;
                align-items: center;
                gap: 15px;
                font-weight: 500;
                padding: 15px 20px;
            }

            &::before {
                background-color: transparent !important;
            }

            .items {
                display: flex;
                flex-direction: column;
                gap: 15px;
                padding: 0px 20px;

                .item {
                    cursor: pointer;
                }
            }
        }
    }

    .containers {
        min-height: 100vh;
        width: 100%;
        border-left: 1px solid #ebebeb;
        max-width: calc(100vw - $sidebar-width);

        .container {
            width: 100%;
            padding: 20px;
            display: block;

            .main-title-wrap {
                display: flex;
                justify-content: space-between;
                align-items: center;
                gap: 25px;
                padding: 20px 0;
                margin-bottom: 20px;

                .title {
                    font-size: $px30;
                    font-weight: 500;
                    min-width: 150px;
                }

                .input-wrap {
                    max-width: 350px;
                }

                .search-input {
                    .MuiInputBase-root {
                        background-color: #f3f5f7 !important;
                        border-radius: 60px !important;
                    }

                    .MuiOutlinedInput-notchedOutline {
                        border: none !important;
                    }
                }
            }

            .form-section {
                display: flex;
                flex-direction: column;
                width: 100%;
                border: 1px solid #ebebeb;
                border-radius: 10px;

                &.no-border {
                    border-color: transparent;
                }

                .top {
                    display: flex;
                    flex-wrap: wrap;
                    gap: 20px;
                    justify-content: space-between;
                    align-items: center;
                    padding: 15px;
                    border-bottom: 1px solid #ebebeb;
                }

                .body {
                    display: flex;
                    flex-direction: column;
                    gap: 20px;
                    min-height: 100px;
                    padding: 15px;

                    &.table-body {
                        padding: 0;

                        .body {
                            padding: 0;
                        }
                    }
                }
            }

            .form-buttons {
                margin-top: 40px;
                display: flex;
                flex-wrap: wrap;
                gap: 15px;

                .button {
                    width: 100%;
                    max-width: 200px;
                }
            }

            .multi-wrap {
                .item {
                    width: 100%;
                }
            }
        }
    }
}

@media screen and (max-width: 1150px) {
    #dashboard-container {
        .side-bar {
            position: absolute;
            background-color: #fff;
            z-index: 99999;
            left: 0;
            transform: translateX(-$sidebar-width);
            transition: transform 0.3s ease;
            border-right: 1px solid #ebebeb;

            &[data-active='true'] {
                transform: translateX(0);
            }
        }

        .containers {
            border-left: none;
            max-width: 100vw;
        }
    }
}

@media screen and (max-width: 960px) {
    #dashboard-container {
        .containers {
            .container {
                .main-title-wrap {
                    flex-wrap: wrap;
                }
            }
        }
    }
}

@import './products';
@import './orders';
@import './categories';
@import './users';
@import './payments';
@import './dashboard';
@import './reports';
@import './settings';
