.reports-container {
    .content {
        display: flex;
        gap: 20px;
        flex-wrap: wrap;

        .report-card {
            background-color: #f3f5f7;
            border-radius: 10px;
            width: 100%;
            max-width: 490px;
            height: 192px;
            padding: 20px;
            display: flex;
            flex-direction: column;
            gap: 10px;

            .title {
                font-weight: 500;
            }

            .description {
                height: 100%;
            }

            .buttons {
                align-self: flex-end;
            }
        }
    }
}
