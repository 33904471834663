.order-all-details-modal {
    .modal-content {
        width: 100%;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        flex-direction: row !important;
    }

    .styled-button {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 20px;
        width: 100% !important;
        max-width: 170px !important;
        min-height: 170px !important;
        background: #f3f5f7 !important;
        color: $black !important;

        .inner-icon {
            color: $black !important;
            font-size: 5em !important;
        }
    }
}
