#footer {
    display: flex;
    flex-direction: column;
    color: #fff;
    width: 100%;
    background-color: $theme;
    min-height: 387px;
    justify-content: center;
    gap: 20px;

    .sections {
        display: flex;
        justify-content: space-between;
        gap: 40px;
        padding: 50px 100px 50px 100px;

        .section {
            display: flex;
            flex-direction: column;
            gap: 20px;

            .main-title {
                font-weight: 700;
                font-size: $px30;
                height: 47px;
                display: flex;
                align-items: center;
            }

            .title {
                display: flex;
                align-items: flex-end;
                font-weight: 500;
                font-size: $px20;
                height: 47px;
            }

            .items {
                display: flex;
                flex-direction: column;
                gap: 15px;
            }

            .socials {
                display: flex;
                align-items: center;
                gap: 15px;
                cursor: pointer;

                .icon {
                    font-size: 1.2em;
                }
            }

            &:nth-child(1) {
                .desc {
                    max-width: 513px;
                }
            }

            &:nth-child(4) {
                .item {
                    display: flex;
                    gap: 15px;

                    .desc {
                        max-width: 300px;
                    }

                    .contact-title {
                        min-width: 60px;
                    }
                }
            }
        }
    }

    .bottom {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        gap: 20px;
        padding: 20px 100px;
        background-color: #ebebeb;
        color: #404040;
    }
}

@media screen and (max-width: 1500px) {
    #footer {
        .sections {
            display: grid;
            grid-template-columns: 50% 50%;
        }
    }
}

@media screen and (max-width: 800px) {
    #footer {
        .sections {
            display: flex;
            flex-direction: column;
            padding: 50px 20px 50px 20px;
            gap: 20px;

            .section {
                &:nth-child(1) {
                    .desc {
                        display: none;
                    }
                }
            }
        }

        .bottom {
            padding: 20px 15px;
            justify-content: center;
        }
    }
}
