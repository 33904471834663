.order-confirm-modal {
    .notice {
        padding: 15px 20px;
        border-bottom: 1px solid #ebebeb;
    }

    .deliver-time {
        display: flex;
        flex-direction: column;
        gap: 7px;
    }

    .modal-buttons {
        justify-content: center;

        .button {
            max-width: 335px !important;
        }
    }
}
