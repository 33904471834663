.user-dashboard {
    .content {
        display: flex;
        flex-wrap: wrap;
        min-height: 70vh;
        gap: 20px;

        .order-card {
            border: 1px solid #ebebeb;
            border-radius: 10px;
            width: 100%;
            max-width: 490px;
            height: max-content;

            .top,
            .mid,
            .bottom {
                padding: 15px;
                width: 100%;
            }

            .top,
            .bottom {
                display: flex;
                gap: 10px;
                justify-content: space-between;
            }

            .top {
                .section {
                    display: flex;
                    align-items: center;
                    gap: 5px;
                }
            }

            .mid {
                display: flex;
                gap: 10px;
                padding: 20px 15px;
                border-top: 1px solid #ebebeb;
                border-bottom: 1px solid #ebebeb;

                .image {
                    max-width: 100px;
                    border-radius: 8px;
                }

                .details {
                    display: flex;
                    flex-direction: column;
                    gap: 5px;

                    .detail {
                        display: flex;
                        align-items: flex-start;
                        gap: 7px;

                        .title {
                            max-width: fit-content;
                            width: 100%;
                        }

                        .value {
                            // word-break: break-all;
                        }
                    }
                }
            }

            .bottom {
                .button {
                    width: 100% !important;
                }
            }
        }
    }
}

@media screen and (max-width: 770px) {
    .user-dashboard {
        .content {
            width: 100%;

            .order-card {
                max-width: 100%;

                .mid {
                    flex-direction: column;
                    align-items: center;
                }

                .bottom {
                    flex-direction: column;
                    align-items: center;
                }
            }
        }
    }
}

@media screen and (max-width: 370px) {
    .user-dashboard {
        .content {
            .order-card {
                .top {
                    flex-direction: column;
                }
            }
        }
    }
}
