.circular-progress {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100px;
    width: 100%;

    .progress {
        color: $theme;
    }
}
