.product-feature-modal {
    .sections {
        display: flex;
        flex-direction: row;
        gap: 25px;

        .section {
            width: 100%;

            &.values-section {
                display: flex;
                flex-direction: column;
                gap: 15px;

                .row-wrap {
                    display: flex;
                    justify-content: space-between;
                    align-items: flex-end;
                    gap: 15px;
                    width: 100%;
                }

                .values {
                    display: flex;
                    flex-direction: column;
                    gap: 10px;

                    .value {
                        border-radius: 6px;
                        padding: 6px 15px;
                        width: 100%;
                        background-color: $light-grey;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 770px) {
    .product-feature-modal {
        .sections {
            flex-direction: column;
        }
    }
}
