.nav-arrow {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff !important;
    box-shadow: $box-shadow;

    .icon {
        color: #000;
    }
}
