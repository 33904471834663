$border-radius: 10px;

.image-upload {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    border: 4px dashed #ebebeb;
    width: 100%;
    max-width: 150px;
    min-height: 150px;
    cursor: pointer;
    transition: all 0.3s;
    border-radius: $border-radius;
    width: 100%;
    overflow: hidden;

    &[data-preview='true'] {
        cursor: default;
    }

    .overlap {
        z-index: 1;
        position: absolute;
        top: 0px;
        left: 0px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        min-height: 100%;
        border-radius: 5px;
        background-color: rgba(0, 0, 0, 0.27);
        transition: all 0.3s ease;
        opacity: 0;

        &:hover {
            opacity: 1;
        }

        &[data-active='true'] {
            opacity: 1;
        }

        &[data-disabled='true'] {
            display: none;
        }

        .edit-icon {
            border-radius: 50%;
            background-color: #fff;
            color: #1c1b1f;
            padding: 10px;
            font-size: 1em;
        }
    }

    &[data-empty='false'] {
        border-style: ridge;
        border-color: rgba(0, 0, 0, 0.27);
    }

    .icon-wrap {
        display: flex;
        flex-direction: column;
        gap: 10px;
        align-items: center;
        justify-content: center;
        font-size: 0.9em;
        font-weight: 600;
        color: #000;

        .icon {
            font-size: 2em;
        }

        .preview-icon {
            font-size: 7rem;
        }
    }

    .image {
        width: 100%;
        max-height: 150px;
        object-fit: contain;
        //border-radius: $border-radius;
    }
}
