#categories {
    display: flex;
    flex-direction: column;
    gap: 40px;

    .main-title {
        text-align: center;
        font-weight: 500;
        font-size: $px30;
    }

    .cards-wrap {
        width: 100%;
        min-height: 70vh;
    }

    .cards {
        display: grid;
        grid-template-columns: repeat(5, minmax(150px, 320px));
        gap: 30px;
        width: 100%;
    }
}

@media screen and (max-width: 1200px) {
    #categories {
        .cards {
            grid-template-columns: repeat(4, minmax(150px, 320px));
        }
    }
}

@media screen and (max-width: 960px) {
    #categories {
        .cards {
            grid-template-columns: repeat(3, minmax(150px, 320px));
        }
    }
}

@media screen and (max-width: 770px) {
    #categories {
        .cards {
            grid-template-columns: repeat(2, minmax(150px, 320px));
        }
    }
}

@media screen and (max-width: 500px) {
    #categories {
        .cards {
            justify-content: center;
            grid-template-columns: repeat(1, minmax(150px, 320px));
        }
    }
}
