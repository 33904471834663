.back-drop {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .progress-wrap {
        display: inline-flex;
        position: relative;

        .value-wrap {
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}
