.file-upload {
    width: 100%;

    .upload-label {
        display: flex;
        flex-direction: column;
        gap: 20px;
        align-items: center;
        justify-content: center;
        min-height: 200px;
        width: 100%;
        border: 2px dashed $grey;
        transition: all 0.3s;
        border-radius: $border-radius;
        cursor: pointer;
        color: $grey;

        .icon {
            color: $grey;
            font-size: 4em;
        }
    }
}
