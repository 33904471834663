$box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.2);
$box-shadow-1: 0px 20px 80px rgba(193, 199, 212, 0.5);

$px36: 2.25em;
$px32: 2em;
$px30: 1.875em;
$px20: 1.25em;
$px25: 1.563em;
$px16: 1em;
$px14: 0.875em;

$font-family: 'Poppins', sans-serif;
$desktop-limit: 1720px;
$mobile-limit: 1150px;
