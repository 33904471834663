.button {
    text-transform: none !important;
    font-size: 1em !important;
    padding-left: 20px !important;
    padding-right: 20px !important;
    gap: 7px !important;
    border-radius: 6px !important;
    font-weight: 400 !important;

    &.max-height {
        height: 100% !important;
    }

    &.no-shadow {
        box-shadow: none !important;
    }

    &:not(.MuiLoadingButton-loading):not(.Mui-disabled) {
        border-color: $theme !important;
        color: $theme !important;

        &.MuiButton-contained {
            background-color: $theme !important;
            color: #fff !important;

            &.hover-btn {
                background-color: $light-grey !important;
                color: #000 !important;

                &:hover {
                    background-color: $theme !important;
                    color: #fff !important;
                }
            }

            .icon {
                color: #fff !important;
            }

            &.grey {
                background-color: #f3f5f7 !important;
                color: #000 !important;
            }
        }

        &.bg-delete {
            background-color: $error !important;
        }
    }

    &.MuiButton-sizeLarge {
        padding: 15px;
    }
}
