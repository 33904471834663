.order-view {
    .modal-content {
        display: flex;
        flex-direction: column;
        gap: 15px;
        padding: 0px !important;

        .section {
            display: flex;
            flex-direction: column;
            gap: 10px;
            margin-top: 20px;
            border-top: 1px solid #ebebeb;

            .title-wrap {
                padding: 15px;
                padding-bottom: 5px;

                .title {
                    font-size: $px20;
                }
            }

            &:first-child {
                margin-top: 0px;
                border-top: none;
            }

            .content {
                display: flex;
                flex-direction: column;
                gap: 15px;
                padding: 15px;
            }
        }

        .image-wrap {
            display: flex;
            //flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            padding-bottom: 15px;
            max-width: 100%;
            gap: 5px;

            &.justify-flex-start {
                justify-content: flex-start;
                overflow-x: auto;
            }

            &.padding-top {
                padding-top: 15px;
            }

            .image {
                max-width: 140px;
                width: 100%;
                object-fit: cover;
                border-radius: 15px;
                min-height: 140px;

                &.small {
                    max-width: 118px;
                    min-height: 118px;
                }
            }
        }

        .detail-wrap {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            gap: 10px;

            .title {
                width: 100%;
                max-width: 250px;
                font-weight: 500;
                text-transform: uppercase;
            }
        }
    }
}

@media screen and (max-width: 960px) {
    .order-view {
        .modal-content {
            .image-wrap {
                flex-wrap: wrap;
                gap: 10px;
            }
        }
    }
}
