.signature-component {
    .m-signature-pad {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }

    .m-signature-pad--body {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    canvas {
        width: 100% !important;
        min-height: 300px !important;
        border-radius: 5px !important;
        border: 1px solid rgba(0, 0, 0, 0.2) !important;
    }

    .button:not(.MuiLoadingButton-loading):not(.Mui-disabled) {
        outline: none !important;
        padding: 5px 10px !important;
        background-color: $theme !important;
        color: #fff !important;
        cursor: pointer;
    }
}
