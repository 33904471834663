.form {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .form-content {
        display: flex;
        flex-direction: column;
        gap: 20px;

        &.overflow-content {
            overflow-y: auto;
            max-height: 70vh;
        }

        &.auto-padding {
            padding: 20px 24px !important;
        }
    }
}
