.whatsapp-icon {
    position: sticky;
    bottom: 20px;
    right: 20px;
    align-self: flex-end;
    z-index: 999;

    .image {
        width: 100px;
        height: 100px;
        object-fit: contain;
    }
}
