.user-employee-register-modal {
    .image-wrap {
        display: flex;
        padding: 15px;
        align-items: center;
        justify-content: center;
    }

    .modal-buttons {
        justify-content: center !important;

        .button {
            max-width: 270px !important;
        }
    }
}
