.order-requested {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
    padding: 30px 20px;

    .icon-wrap {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #def7ec;
        border-radius: 50%;
        width: 60px;
        height: 60px;

        .icon {
            font-size: 2.5em !important;
            color: #03543f;
        }
    }

    .text {
        text-align: center;
    }

    .button {
        width: 100% !important;
        max-width: 300px;
    }
}
