@import './colors';
@import './variables';
@import '~react-image-gallery/styles/scss/image-gallery.scss';

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html,
body {
    cursor: default;
    position: fixed;
    overflow-y: auto;
    height: 100vh;
    width: 100%;
    overflow-x: hidden;
    font-family: $font-family;
    scroll-behavior: smooth;
}

.disable-scroll {
    height: 100vh;
    overflow: hidden;
}

a {
    text-decoration: none;
    color: inherit;
}

.main {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    min-height: 70vh;
}

/* .material-symbols-rounded {
    font-variation-settings: 'FILL' 0, 'wght' 400, 'GRAD' 0, 'opsz' 48;

    &.contained {
        font-variation-settings: 'FILL' 1, 'wght' 400, 'GRAD' 0, 'opsz' 48;
    }

    &.contained-w-700 {
        font-variation-settings: 'FILL' 1, 'wght' 700, 'GRAD' 0, 'opsz' 48;
    }

    &.w-500 {
        font-variation-settings: 'wght' 500;
    }
    &.w-600 {
        font-variation-settings: 'wght' 600;
    }
    &.w-700 {
        font-variation-settings: 'wght' 700;
    }
} */

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    min-height: 100vh;
}

.container-mw {
    width: 100%;
    padding-left: 3%;
    padding-right: 3%;
    max-width: $desktop-limit;
}

.container-pad {
    padding-top: 30px;
    padding-bottom: 30px;
}

.plain-container {
    padding: 20px;
    width: 100%;

    pre {
        max-width: 800px;
        white-space: break-spaces;
    }
}

.popper {
    padding: 5px 15px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: $box-shadow;
    display: flex;
    flex-direction: column;
    gap: 10px;
    max-width: 150px;
    width: 100%;
    z-index: 99999;

    &.w-mx {
        max-width: max-content;
    }

    &.sm {
        max-width: max-content;
    }

    &.mt-10 {
        margin-top: 10px !important;
    }

    &.ml-30 {
        margin-left: -30px !important;
    }

    &.g-sm {
        gap: 5px;
    }

    .divider {
        width: 100%;
        height: 1px;
        background-color: #d8d8de;
    }

    .item {
        position: relative;
        display: flex;
        align-items: center;
        gap: 15px;
        padding: 10px 0;
        color: #939393;
        cursor: pointer;
        transition: all 0.5s ease;
        font-weight: 600;
        user-select: none;
        transition: all 0.3s ease;

        &.w-400 {
            font-weight: 400;
        }

        &.fs-sm {
            font-size: 0.8em;
        }

        &.cl-default {
            color: #2f2f2f;
        }

        .icon {
            font-size: 1.3rem;
        }

        &:hover {
            color: $theme;
        }
    }
}

.multi-wrap {
    display: flex;
    gap: 20px;

    &.align-center {
        align-items: center;
    }
}

.detailed-checkbox {
    display: flex;
    flex-direction: column;
    gap: 5px;
    user-select: none;

    .MuiFormControlLabel-root {
        align-items: flex-start !important;
        gap: 10px;
    }

    .MuiCheckbox-root {
        padding: 5px !important;
    }

    .desc {
        color: $grey;
        max-width: 440px;
    }
}

.link-btn {
    color: $theme;
    text-decoration: underline;
    cursor: pointer;
}

@media screen and (max-width: 1150px) {
    .multi-wrap {
        &.triple {
            flex-direction: column;
        }

        &.quadruple {
            flex-wrap: wrap;
        }
    }
}

@media screen and (max-width: 770px) {
    .multi-wrap {
        &.double {
            flex-direction: column;
        }

        &.quadruple {
            flex-wrap: wrap;
        }
    }
}

@media screen and (max-width: 770px) {
    .container-mw {
        padding-left: 20px;
        padding-right: 20px;
    }
}
