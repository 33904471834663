.settings-container {
    display: flex !important;
    flex-direction: column;
    gap: 30px;

    .main-title-wrap {
        width: 100%;
        margin-bottom: 0px !important;
    }

    .content {
        display: flex !important;
        flex-direction: column;
        gap: 30px;
        width: 100%;
    }

    .banners {
        display: grid !important;
        grid-template-columns: repeat(auto-fill, minmax(150px, 355px));
        gap: 20px;

        .banner-image {
            aspect-ratio: 96 / 35;
            max-width: 100% !important;
            max-height: 130px !important;
            align-items: normal;

            .image {
                object-fit: cover;
            }
        }
    }

    .profile-info {
        .buttons {
            display: flex;
            justify-content: flex-end;
            padding-top: 20px;

            .button {
                max-width: 350px !important;
            }
        }
    }
}
