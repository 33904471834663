$theme: #752888;
$light-theme: #efe5fe;
$dark-grey: #404040;
$light-grey: #f3f5f7;
$black: #000000;
$white: #ffffff;
$error: #d32f2f;
$error-1: #dc2626;
$grey: #888888;
$grey-1: #8e8e8e;
$green: #36c703;
$yellow: #f3cc00;
$orange: #c77903;
$blue: #0368c7;

.bg-theme {
    background: $theme;
}

.color-theme {
    color: $theme;
}

.color-error-1 {
    color: $error-1;
}
