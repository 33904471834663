#dashboard-user {
    .table {
        .blacklisted {
            background-color: #ffe8e8;
        }
    }
}

@import './register-employee';
@import './edit';
