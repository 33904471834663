.navbar {
    display: flex;
    flex-direction: column;
    position: relative;
    align-items: center;
    border-bottom: 1px solid #ebebeb;
    z-index: 999;

    .link,
    .logo {
        z-index: 99999;
    }

    .top {
        z-index: 9999;
    }

    .body {
        z-index: 999;
    }

    .top {
        position: relative;
        min-height: 48px;
        background-color: $theme;
        // z-index: 999999;
        width: 100%;
    }

    .body {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 40px;
        min-height: 90px;
        padding: 20px 3%;
        max-width: $desktop-limit;
        width: 100%;

        .logo-wrap {
            display: flex;
            align-items: center;
            width: 100%;
            max-width: max-content;

            .logo {
                object-fit: contain;
                max-width: 167px;
                width: 100%;
            }
        }

        .input {
            .MuiInputBase-root {
                background-color: #f3f5f7 !important;
                border-radius: 60px !important;
            }

            .MuiOutlinedInput-notchedOutline {
                border: none !important;
            }
        }

        .link {
            display: flex;
            gap: 10px;
            cursor: pointer;
            position: relative;
            width: max-content;

            &:hover {
                ~ .content-wrap {
                    display: flex;
                }
            }
        }

        .search-bar {
            width: 100%;
            padding-left: 22px;
            padding-right: 22px;
        }

        .buttons {
            display: flex;
            gap: 10px;

            .notification-btn {
                color: #000 !important;
            }

            .account-btn {
                display: flex;
                gap: 15px !important;
                color: #000 !important;
                width: 100%;
                //max-width: 120px;

                .icon {
                    max-width: 1.8rem !important;
                    max-height: 1.8rem !important;
                    font-size: 1.8rem !important;
                }

                .name {
                    width: max-content;
                }
            }
        }
    }

    .content-wrap {
        position: absolute;
        background-color: #fff;
        min-height: 500px;
        width: 100%;
        top: 0;
        left: 0;
        display: flex;
        justify-content: center;
        display: none;
        padding-top: 140px;
        border-bottom: 1px solid #ebebeb;

        &:hover {
            display: flex;
        }

        .content {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            gap: 50px;
            max-width: 1350px;
            width: 100%;
            padding: 40px 100px;

            .category {
                display: flex;
                flex-direction: column;
                gap: 30px;

                .title {
                    font-weight: 500;
                    color: $theme;
                }

                .nav-links {
                    display: flex;
                    flex-direction: column;
                    gap: 15px;
                }
            }
        }
    }

    &[data-dashboard='true'] {
        .nav-item,
        .input {
            display: none;
        }
    }

    &.mobile {
        position: relative;

        .body {
            display: flex;
            flex-direction: column;
            gap: 10px;
            padding: 20px;
            background-color: #fff;

            .wrap {
                display: flex;
                justify-content: space-between;
                align-items: center;
                gap: 20px;
                width: 100%;
            }

            .menu-button {
                .icon {
                    font-size: 1.3em !important;
                    color: #000 !important;
                }
            }

            .buttons {
                display: flex;
                align-items: center;
                gap: 5px;
            }
        }

        .menu {
            position: fixed;
            z-index: 99;
            top: 0;
            left: 0;
            width: 100%;
            height: 100vh;
            overflow-y: auto;
            overflow-x: hidden;
            background-color: #fff;
            transform: translateX(100vw);
            transition: transform 0.3s ease;
            padding-top: 200px;
            padding-bottom: 50px;

            &[data-active='true'] {
                transform: translateX(0);
            }

            .links {
                display: flex;
                flex-direction: column;
                gap: 25px;
                width: 100%;

                .nav-item {
                    padding: 0px 16px;

                    &.no-padding {
                        padding: 0 !important;
                    }
                }

                .accordion {
                    box-shadow: none !important;
                    border-bottom: 1px solid #ebebeb;
                }

                .links-wrap {
                    .content {
                        display: flex;
                        flex-direction: column;
                        gap: 30px;

                        .category {
                            display: flex;
                            flex-direction: column;
                            gap: 15px;

                            .title-wrap {
                                display: flex;
                                justify-content: space-between;
                                align-items: center;
                                gap: 20px;

                                .title {
                                    color: $theme;
                                    font-weight: 500;
                                }

                                .button {
                                    color: $dark-grey !important;
                                }
                            }

                            .nav-links {
                                display: grid;
                                grid-template-columns: repeat(2, minmax(100px, 250px));
                                gap: 15px;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 770px) {
    .navbar {
        .content-wrap {
            .content {
                flex-direction: column;
                padding: 40px 30px;
            }
        }
    }
}

@import './dashboard';
