.product-price-modal {
    .sections {
        display: flex;
        flex-direction: column;
        gap: 15px;

        .section {
            display: flex;
            flex-direction: column;
            gap: 20px;

            .title-wrap {
                display: flex;
                flex-wrap: wrap;
                gap: 15px;
                justify-content: space-between;
                align-items: center;
                padding: 15px 20px;
                border-bottom: 1px solid #ebebeb;

                .icon {
                    color: #000 !important;
                }
            }

            .content {
                padding: 0 20px;

                &.feature-values {
                    .row-wrap {
                        display: flex;
                        align-items: flex-start;
                        gap: 15px;
                        width: 100%;
                        padding-bottom: 10px;
                        border-bottom: 1px solid #ebebeb;

                        &:last-child {
                            border-bottom-color: transparent;
                        }

                        .multi-wrap {
                            width: 100%;
                        }

                        .sq-theme {
                            margin-top: 35px;
                            width: max-content;
                            height: max-content;
                        }
                    }
                }
            }
        }
    }

    .label-input {
        .label {
            color: $grey;
            margin-right: 7px;
        }
    }
}

@media screen and (max-width: 770px) {
    .product-price-modal {
        .sections {
            .section {
                .content {
                    &.feature-values {
                        .row-wrap {
                            flex-direction: column;
                            align-items: flex-start;

                            .sq-theme {
                                margin-top: 0;
                            }
                        }
                    }
                }
            }
        }
    }
}
