.dashboard-collected-payments {
    .status-chip {
        height: 29px !important;
        min-width: 100px;
        color: #fff;

        &[data-status='PENDING'] {
            background-color: $yellow;
        }

        &[data-status='CONFIRMED'] {
            background-color: $green;
        }
    }
}
