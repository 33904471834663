$mx-width: 318.38px;

.detail-card {
    display: block;
    width: 90%;

    &.limit-width {
        .content,
        .image {
            max-width: $mx-width;
        }
    }

    &:hover {
        .image-wrap {
            .image {
                transform: scale(1.05);
            }
        }
    }

    .content {
        display: flex;
        flex-direction: column;
        gap: 20px;
        width: 100%;
    }

    .image-wrap {
        width: 100%;
        height: 300px;
        border-radius: 15px;
        overflow: hidden;

        .image {
            width: 100%;
            height: 100%;
            max-width: 328.96px;
            object-fit: contain;
            transition: all 0.4s ease;
        }
    }

    .details {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 9px;

        .price {
            color: $dark-grey;
        }

        .title {
            overflow: hidden;
            font-weight: 500;
            text-overflow: ellipsis;
            white-space: nowrap;
            max-width: 120px;
            max-width: 120px;
        }
    }

    .button {
        font-weight: 400 !important;
    }
}

@media screen and (max-width: 1350px) {
    .detail-card {
        .image-wrap {
            .image {
                object-fit: contain;
            }
        }
    }
}

@media screen and (max-width: 770px) {
    .detail-card {
        &[data-hide-on-mobile='true'] {
            .button {
                display: none;
            }
        }
    }
}

@media screen and (max-width: 550px) {
    .detail-card {
        .image-wrap {
            height: 180px;
        }
    }
}
