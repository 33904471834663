$side-bar-width: 326px;

#products {
    position: relative;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    min-height: 100vh;
    padding-bottom: 0px !important;

    .side-bar {
        width: 100%;
        max-width: $side-bar-width;
        display: flex;
        flex-direction: column;
        gap: 5px;
        padding: 20px;
        padding-left: 0px;

        .content {
            display: flex;
            flex-direction: column;
            // gap: 20px;

            .MuiPaper-root {
                margin: 0 !important;
            }
        }

        .accordion {
            box-shadow: none !important;
            border: none !important;

            &::before {
                background-color: transparent !important;
            }

            .accordion-summary {
                gap: 20px;
                padding-left: 0 !important;
            }

            .accordion-title {
                font-weight: 500;
                font-size: $px20;
            }

            .accordion-details {
                .item {
                    cursor: pointer;
                }
            }
        }

        .mobile-close-wrap {
            display: none;
            justify-content: flex-end;
            align-items: center;
            width: 100%;

            .icon {
                font-size: 1em !important;
            }
        }

        .items {
            display: flex;
            flex-direction: column;
            gap: 25px;
        }
    }

    .body {
        padding: 30px;
        width: 100%;
        border-left: 1px solid #ebebeb;

        .main-title-wrap {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: space-between;
            gap: 25px;
            margin-bottom: 30px;

            .filter-button {
                display: none;

                .icon {
                    font-size: 1em !important;
                }
            }

            .main-title {
                font-weight: 500;
                font-size: $px30;
            }
        }

        .cards-wrap {
            width: 100%;
            min-height: 70vh;
        }

        .cards {
            display: grid;
            grid-template-columns: repeat(4, minmax(170px, 550px));
            width: 100%;
            gap: 40px;
            justify-content: space-between;
        }
    }
}

@media screen and (max-width: 1220px) {
    #products {
        .body {
            .cards {
                grid-template-columns: repeat(3, minmax(170px, 250px));
            }
        }
    }
}

@media screen and (max-width: $mobile-limit) {
    #products {
        padding: 20px;
    }
}

@media screen and (max-width: 960px) {
    #products {
        .side-bar {
            position: absolute;
            width: 100%;
            min-height: 100%;
            background-color: #fff;
            top: 0;
            left: 0;
            z-index: 1;
            transform: translateX(-$side-bar-width);
            transition: transform 0.3s ease;
            padding-left: 30px;

            &[data-active='true'] {
                transform: translateX(0);
            }

            .mobile-close-wrap {
                display: flex;
            }
        }

        .body {
            border-left: none;
            padding: 20px;

            .main-title-wrap {
                .filter-button {
                    display: block;
                }
            }

            .cards {
                grid-template-columns: repeat(3, minmax(170px, 250px));
            }
        }
    }
}

@media screen and (max-width: 770px) {
    #products {
        .body {
            .cards {
                grid-template-columns: repeat(2, minmax(170px, 250px));
            }
        }
    }
}

@media screen and (max-width: 500px) {
    #products {
        .body {
            .cards {
                justify-content: center;
                grid-template-columns: repeat(2, minmax(170px, 250px));

                .detail-card {
                    .details {
                        align-items: center;
                    }
                }
            }
        }
    }
}

@import './product';
