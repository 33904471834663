#dashboard-category {
    .form-section {
        .body {
            display: grid !important;
            grid-template-columns: 70% 30%;

            .section {
                display: flex;
                flex-direction: column;
                gap: 30px;

                &.image-section {
                    &[data-hide='true'] {
                        display: none;
                    }
                }

                .hide-item {
                    &[data-hide='true'] {
                        display: none;
                    }
                }

                &:nth-child(2) {
                    padding-top: 15px;
                    align-items: center;
                }

                .category-image {
                    $size: 200px;
                    max-width: $size;
                    min-height: $size;
                }
            }
        }
    }
}

@media screen and (max-width: 770px) {
    #dashboard-category {
        .form-section {
            .body {
                display: flex !important;
                flex-direction: column-reverse !important;
            }
        }
    }
}
