.supply-view {
    overflow-y: auto;

    .modal-content {
        display: flex;
        flex-direction: column;
        gap: 15px;

        .images-wrap {
            max-width: 100%;
            overflow-x: auto;

            .image {
                width: 100%;
                max-width: 118px;
                min-height: 118px;
                object-fit: cover;
                border-radius: 15px;
            }
        }

        .button-wrap {
            display: flex;
            justify-content: flex-end;
        }

        .detail-wrap {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            gap: 20px;

            &.column {
                align-items: flex-start;
                flex-direction: column;
            }

            .title {
                width: 100%;
                max-width: 220px;
                font-weight: 500;
                text-transform: uppercase;
                display: flex;
                align-items: center;
                justify-content: space-between;
            }
        }
    }

    .section-title {
        display: flex;
        padding: 20px;
        border-top: 1px solid #ebebeb;
        border-bottom: 1px solid #ebebeb;
        align-items: center;
        color: #000;
        font-weight: 500;
        font-size: $px20;
    }
}
