.page-table {
    overflow-x: auto;

    .table {
        min-width: 750px;

        th,
        td {
            font-family: $font-family !important;
        }

        .status-header {
            .multi-select {
                background-color: #fff;
            }
        }

        .MuiPaper-root {
            min-width: max-content !important;
            overflow-x: hidden !important;
        }

        .MuiTableCell-root {
            border-bottom-color: #eff0f6 !important;
            color: #2f2f2f;
        }

        .icon-button-cell {
            width: 70px;
        }

        .mw-button-cell {
            max-width: 200px;
            width: max-content;
        }

        .data-row {
            border-bottom: 2px solid #ebebeb !important;

            &:nth-child(even) {
                background-color: $light-grey !important;
            }
        }
    }

    .head {
        background-color: $light-grey;
    }

    .pagination {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        padding: 5px;
        background-color: $light-grey;
        min-width: 750px;
        min-height: 25px;

        .MuiTablePagination-selectLabel {
            font-weight: 600;
        }
    }
}
