@import '../colors';
@import '../variables';

.popup-modal {
    z-index: 99999 !important;

    .popup-modal-title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: #000;
        border-bottom: 1px solid #ebebeb;

        .popup-close-icon {
            .icon {
                color: #000 !important;
            }
        }
    }

    .popup-modal-content {
        padding-top: 20px !important;
        padding: 0 !important;
        color: #000;
        // overflow-y: hidden !important;
        overflow-y: auto !important;

        &.auto-padding {
            padding: 20px 24px !important;
        }

        .modal-content {
            display: flex;
            flex-direction: column;
            gap: 20px;
            padding: 20px 24px !important;
        }

        .modal-buttons {
            padding: 20px;
            display: flex;
            justify-content: flex-end;
            flex-wrap: wrap;
            gap: 10px;
            border-top: 1px solid #ebebeb;
            background-color: #fff;

            .button {
                width: 100%;
                max-width: 120px;
            }
        }
    }

    @import './containers/product';
    @import './containers/user';
    @import './containers/order';
    @import './containers/views';
    @import './containers/payment';
}

.MuiPopover-root {
    z-index: 99999 !important;
}
