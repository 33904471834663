$banner-height: 70vh;
$banner-max-height: 700px;

.react-multiple-carousel__arrow {
    z-index: 100 !important;
}

#home {
    .top-carousel {
        width: 100%;

        .carousel {
            max-height: $banner-max-height;

            .react-multiple-carousel__arrow {
                &::before {
                    content: '';
                }
            }

            .navigator-icon {
                background-color: #ffffff;
                color: #1c1b1f;
            }
        }

        .carousel-paper {
            width: 100%;

            .carousel-item {
                display: flex;

                .image {
                    max-height: $banner-max-height;
                    width: 100%;
                    object-fit: contain;
                }
            }
        }
    }

    .sections {
        display: flex;
        flex-direction: column;
        gap: 100px;
        padding-top: 45px;
        padding-bottom: 45px;

        .section {
            display: flex;
            flex-direction: column;
            gap: 60px;

            .main-title-wrap {
                display: flex;
                gap: 20px;
                align-items: center;
                justify-content: center;
                position: relative;

                .main-title {
                    text-align: center;
                    font-size: $px30;
                    font-weight: 500;
                }

                .button {
                    position: absolute;
                    right: 0;
                }
            }

            .cards {
                display: grid;
                grid-template-columns: repeat(6, minmax(150px, 320px));
                gap: 30px;
                width: 100%;

                .fixed-card {
                    width: 100%;
                    max-width: 318.38px;

                    .content {
                        background-color: $theme;

                        .title {
                            color: #fff;
                        }

                        .arrow {
                            position: absolute;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            width: 51.5px;
                            height: 51.5px;
                            background-color: #fff;
                            border-radius: 50%;
                            bottom: 25px;
                            right: 25px;

                            .icon {
                                font-size: 2rem !important;
                                transform: rotateZ(45deg);
                            }
                        }
                    }
                }
            }
        }
    }

    .react-multiple-carousel__arrow {
        &::before {
            content: '';
        }
    }

    .multi-carousel {
        width: 100%;

        .detail-card-wrap {
            display: flex;
            justify-content: center;
            // padding-right: 20px;
        }
    }

    .testimonials-section {
        display: flex;
        gap: 40px;
        padding-top: 45px;
        padding-bottom: 45px;

        .section {
            width: 100%;
            max-width: 50%;

            .card {
                display: flex;
                align-items: center;
                gap: 30px;
                background-color: $light-theme;
                border-radius: 15px;
                padding: 40px;
                box-shadow: none;

                .details {
                    display: flex;
                    flex-direction: column;
                    gap: 18px;
                    text-align: center;

                    .detail-title {
                        font-weight: 500;
                        font-size: $px25;
                    }

                    .button {
                        background-color: $theme;
                        color: #fff;
                        max-width: 205px;
                    }
                }

                .image {
                    max-width: 236px;
                    object-fit: contain;
                }
            }

            &.testimonials {
                display: flex;
                flex-direction: column;
                gap: 15px;
                align-items: center;
                justify-content: center;

                .testimonials-title {
                    font-weight: 500;
                    font-size: $px25;
                    margin-bottom: 20px;
                }

                .carousel-wrap {
                    width: 100%;

                    .content {
                        display: flex;
                        flex-direction: column;
                        gap: 25px;
                        max-width: 65%;
                        text-align: center;

                        .name {
                            color: $grey;
                        }
                    }

                    .testimonials-carousel {
                        .carousel-paper {
                            display: flex;
                            justify-content: center;
                            width: 100%;
                            box-shadow: none !important;
                        }

                        .react-multiple-carousel__arrow {
                            &::before {
                                content: '';
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1300px) {
    #home {
        .sections {
            .section {
                .cards {
                    justify-content: center;
                    grid-template-columns: repeat(4, minmax(150px, 320px));
                }
            }
        }

        .testimonials-section {
            .card {
                flex-direction: column;

                .details {
                    align-items: center;

                    .desc {
                        max-width: 60%;
                        text-align: center;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1000px) {
    #home {
        .sections {
            .section {
                .cards {
                    justify-content: center;
                    grid-template-columns: repeat(3, minmax(150px, 320px));
                }
            }
        }

        .testimonials-section {
            flex-direction: column;

            .card {
                flex-direction: row;
                max-width: 100%;
            }

            .section {
                max-width: 100%;
            }
        }
    }
}

@media screen and (max-width: 770px) {
    #home {
        .react-multiple-carousel__arrow {
            // background-color: #ffffff90 !important;
            color: #1c1b1f;
            min-width: 35px !important;
            min-height: 35px !important;

            .Icon {
                font-size: 0.8em;
            }
        }

        .sections {
            gap: 50px;

            .section {
                gap: 30px;

                .main-title-wrap {
                    flex-wrap: wrap;
                    justify-content: space-between;

                    .main-title {
                        font-size: $px20;
                    }

                    .button {
                        position: relative;
                    }
                }

                .cards {
                    justify-content: center;
                    grid-template-columns: repeat(2, minmax(150px, 320px));
                }
            }
        }

        .testimonials-section {
            .card {
                padding: 20px 15px !important;
                flex-direction: column;

                .details {
                    .desc {
                        max-width: 85%;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 330px) {
    #home {
        .sections {
            .section {
                .main-title-wrap {
                    flex-direction: column;
                    align-items: center;
                }
            }
        }
    }
}
