.dashboard-orders {
    .status-chip {
        height: 29px !important;
        min-width: 100px;

        &[data-status='PROCESSING'] {
            color: #fff;
            background-color: $error-1;
        }

        &[data-status='CONFIRMED'] {
            color: #fff;
            background-color: $green;
        }
    }

    &.all-orders {
        .status-chip {
            &[data-status='PROCESSING'] {
                color: #fff;
                background-color: $yellow;
            }

            &[data-status='CONFIRMED'],
            &[data-status='DELIVERED'],
            &[data-status='RECEIVED'] {
                color: #fff;
                background-color: $green;
            }

            &[data-status='REJECTED'] {
                color: #fff;
                background-color: $error-1;
            }
        }
    }

    .title-wrap {
        display: flex;
        flex-direction: column;
        gap: 5px;
        max-width: 700px;
    }
}

@import './user-orders';
