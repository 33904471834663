.payment-history {
    .payment-card {
        display: flex;
        flex-direction: column;
        gap: 20px;
        padding: 24px;
        background: linear-gradient(0deg, #f3f5f7, #f3f5f7), linear-gradient(0deg, #ebebeb, #ebebeb);
        border-radius: 10px;

        &.top-content {
            border-radius: 0px;
        }

        .section-title {
            font-size: $px20;
        }
    }

    .details {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }

    .detail-wrap {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        gap: 10px;

        .title {
            width: 100%;
            max-width: 250px;
            font-weight: 500;
            text-transform: uppercase;
        }
    }
}
