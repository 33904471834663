.product-view {
    .modal-content {
        display: flex;
        flex-direction: column;
        gap: 15px;

        .image-wrap {
            display: flex;
            //flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            margin-bottom: 20px;
            gap: 5px;

            .image {
                max-width: 140px;
                width: 100%;
                object-fit: cover;
                border-radius: 15px;
                min-height: 140px;
            }
        }

        .detail-wrap {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            gap: 10px;

            .title {
                width: 100%;
                max-width: 250px;
                font-weight: 500;
                text-transform: uppercase;
            }
        }
    }
}

@media screen and (max-width: 960px) {
    .product-view {
        .modal-content {
            .image-wrap {
                flex-wrap: wrap;
                gap: 10px;
            }
        }
    }
}
