#register {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    padding-top: 50px;
    padding-bottom: 50px;

    .card {
        display: flex;
        flex-direction: column;
        gap: 25px;
        align-items: center;
        border: 1px solid #ebebeb;
        border-radius: 10px;
        padding: 40px;
        max-width: 790px;
        width: 100%;

        .logo {
            max-width: 170px;
            width: 100%;
            object-fit: contain;
        }

        .form {
            width: 100%;

            .double-wrap {
                display: flex;
                gap: 30px;
                align-items: center;
            }

            .redirect-link {
                text-align: center;
            }

            .note-link {
                text-align: center;
            }

            .link {
                color: $theme;
            }

            .button {
                margin-top: 20px;
                align-self: center;
                max-width: 330px;
            }
        }
    }
}

@media screen and (max-width: 770px) {
    #register {
        .card {
            .form {
                .double-wrap {
                    flex-direction: column;
                }
            }
        }
    }
}
