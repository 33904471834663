.dashboard-collected-payments {
    .handover-wrap {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        gap: 25px;

        .title {
            font-weight: 500;
        }
    }
}
