#product {
    .top-wrap {
        margin-bottom: 40px;

        .breadcrumbs {
            color: #000;

            .product-title {
                font-weight: 500;
            }
        }
    }

    .body {
        display: flex;
        flex-direction: column;
        gap: 40px;
        width: 100%;

        .sections {
            display: flex;
            gap: 40px;
        }
    }

    .image-slider-section {
        width: 100%;
        max-width: 580px;

        .image-gallery {
            .image-gallery-slides {
                border-radius: 12px;
            }

            .image-gallery-slide {
                border-radius: 12px;
            }

            .image-gallery-image {
                max-width: 578px;
                height: 578px;
                object-fit: cover;
                border-radius: 12px;
            }

            .image-gallery-thumbnails {
                max-width: 90vw !important;
            }

            .image-gallery-thumbnails-container {
                display: flex;
                align-items: center;
                gap: 7px;
            }

            .image-gallery-thumbnail {
                width: 136px !important;
                height: 136px !important;
            }

            .image-gallery-thumbnail {
                &:hover {
                    border-color: transparent !important;
                }
            }

            .image-gallery-thumbnail-image {
                object-fit: cover;
                border-radius: 12px;
                width: 136px;
                height: 136px;
                cursor: pointer;
            }

            .active {
                border-color: transparent !important;
            }
        }
    }

    .details-section {
        display: flex;
        flex-direction: column;
        gap: 30px;
        width: 100%;

        .name {
            font-weight: 500;
            font-size: $px36;

            &[data-loading='true'] {
                max-width: 300px;
            }
        }

        .form {
            .note-txt {
                &.warning {
                    color: $error-1;
                }
            }

            .double-wrap {
                display: grid;
                grid-template-columns: repeat(2, minmax(200px, 320px));
                gap: 20px;

                .select-wrap {
                    display: flex;
                    flex-direction: column;
                    gap: 7px;
                    width: 100%;

                    .link {
                        color: $theme;
                        font-size: 1em;
                        text-decoration: underline;
                    }
                }
            }

            .accordion {
                box-shadow: none !important;
                border: 1px solid #ebebeb;
                max-width: 670px;

                &::before {
                    background-color: transparent !important;
                }
            }

            .payment-details {
                display: flex;
                flex-direction: column;
                gap: 15px;
                padding: 18px;
                background-color: #f3f5f7;
                max-width: 346px;
                width: 100%;
                border-radius: 6px;
            }

            .price {
                margin-top: 30px;
                font-weight: 500;
                font-size: $px30;
            }

            .button {
                max-width: max-content;
                font-weight: 400 !important;
            }
        }
    }

    .description {
        display: flex;
        flex-direction: column;
        gap: 20px;
        padding: 40px;
        border: 1px solid #ebebeb;
    }
}

@media screen and (max-width: 1320px) {
    #product {
        .body {
            .sections {
                flex-direction: column;
            }
        }
    }
}

@media screen and (max-width: 770px) {
    #product {
        .image-slider-section {
            .image-gallery {
                .image-gallery-image {
                    max-width: 378px;
                    height: 378px;
                }

                .image-gallery-thumbnail {
                    width: 116px !important;
                    height: 116px !important;
                }

                .image-gallery-thumbnail-image {
                    width: 116px;
                    height: 116px;
                }

                .image-gallery-thumbnails-container {
                    //justify-content: center;
                }
            }
        }

        .details-section {
            .name {
                font-size: $px20;
            }

            .form {
                .double-wrap {
                    display: flex;
                    flex-direction: column;
                }
            }
        }

        .description {
            padding: 20px;
        }
    }
}
