#login {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 80vh;

    .card {
        display: flex;
        flex-direction: column;
        gap: 25px;
        align-items: center;
        border: 1px solid #ebebeb;
        border-radius: 10px;
        padding: 40px;
        max-width: 420px;
        width: 100%;

        .forgot-password-text {
            align-self: flex-end;
        }

        .logo {
            max-width: 170px;
            width: 100%;
            object-fit: contain;
        }

        .form {
            width: 100%;

            .redirect-link {
                text-align: center;
            }

            .link {
                color: $theme;
            }

            .button {
                margin-top: 20px;
                align-self: center;
                max-width: 330px;
            }
        }
    }
}
