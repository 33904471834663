.sun-editor {
    border-radius: 4px;
    overflow: hidden;

    .se-placeholder {
        font-family: $font-family;
    }
}

.sun-editor-editable {
    &.preview {
        padding: 0 !important;
        font-family: inherit;
        font-size: inherit;

        &.auto-padding {
            padding: 20px !important;
        }
    }
}

.se-dialog-content {
    * {
        font-family: $font-family !important;
    }
}
