#forgot-password {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 80vh;

    .card {
        display: flex;
        flex-direction: column;
        gap: 25px;
        align-items: center;
        border: 1px solid #ebebeb;
        border-radius: 10px;
        padding: 40px;
        max-width: 420px;
        width: 100%;
        position: relative;

        .form-wrap {
            display: flex;
            width: 100%;
            transition: all 0.4s ease;

            &[data-active='false'] {
                display: none;
            }
        }

        .title-wrap {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 10px;

            .desc {
                text-align: center;
                color: $grey;
                font-size: $px14;
            }
        }

        .forgot-password-text {
            align-self: flex-end;
        }

        .form {
            width: 100%;

            .redirect-link {
                text-align: center;
            }

            .link {
                color: $theme;
            }

            .button {
                margin-top: 20px;
                align-self: center;
                max-width: 330px;
            }
        }
    }
}
