.user-orders-view {
    .modal-content {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }

    .order {
        display: flex;
        flex-direction: column;
        gap: 20px;
        width: 100%;
        padding: 20px;
        border: 1px solid #ebebeb;

        .multi-wrap {
            width: 100%;

            .detail-wrap,
            .button {
                width: 100%;
            }

            .detail-wrap {
                display: flex;
                gap: 10px;
                align-items: center;
                font-weight: 500;
            }

            .status-chip {
                height: 29px !important;
                min-width: 100px;

                &[data-status='PROCESSING'] {
                    color: #fff;
                    background-color: $yellow;
                }

                &[data-status='CONFIRMED'] {
                    color: #fff;
                    background-color: $orange;
                }

                &[data-status='RECEIVED'] {
                    color: #fff;
                    background-color: $blue;
                }

                &[data-status='CLEARED'] {
                    color: #fff;
                    background-color: $green;
                }

                &[data-status='REJECTED'] {
                    color: #fff;
                    background-color: $error-1;
                }
            }
        }
    }
}
